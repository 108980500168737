<div class="row">
  @for(data of featuresdata;track $index){
  <div class="col-lg-4 col-md-6 mt-4 pt-2">
    <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow" 
    href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#Valores" (click)="openModal(data)">
      <div class="icon text-center rounded-circle me-3">
        <i-feather name="{{data.icon}}" class="fea icon-ex-md"></i-feather>
      </div>
      <div class="flex-1">
        <h4 class="title mb-0">{{data.title}}</h4>
      </div>
    </div>
    <!-- Modal Content Start -->              
    <div class="modal fade" id="Valores" tabindex="-1" aria-hidden="true">                  
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content rounded shadow border-0">
                <div class="modal-body py-5">
                    <div class="text-center" *ngIf="valores">
                      <img  [src]="valores.src" class="img-fluid" alt="" style="height: 200px; width:300px;">
                      <h4 >{{ valores.title }}</h4>
                      <p class="text-muted mb-0" >{{ valores.description }}</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ general.btn_close }}</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal Content End -->
  </div>
}
  <!--end col-->

  <!-- <div class="col-12 mt-4 pt-2 text-center">
    <a href="javascript:void(0)" class="btn btn-primary">See More <i class="uil uil-angle-right"></i></a>
  </div> -->
  <!--end col-->
</div>