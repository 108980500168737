import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-page-aboutus-two',
  templateUrl: './page-aboutus-two.component.html',
  styleUrls: ['./page-aboutus-two.component.css']
})

/**
 * Aboutus Two Component
 */
export class PageAboutusTwoComponent implements OnInit {
  navClass = 'nav-light';
  
      // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true
  
  home: any = {};
  general: any = {};
  about: any = {};
  item: any [] = [];  
 url = "assets/images/technologies/";  
   x: any[] = [
    {
      id: "1",
      titulo: "PHP Laravel",
      image: this.url + "angular.jpg"
    },
    {
      id: "2",
      titulo: "C#",
      image: this.url + "react.jpg"
    },
    {
      id: "3",
      titulo: "Angular",
      image: this.url + "angular.jpg"
    },
    {
      id: "4",
      titulo: "SQL",
      image: this.url + "react.jpg"
    },
    {
      id: "5",
      titulo: "HTML5",
      image: this.url + "angular.jpg"
    },
    {
      id: "6",
      titulo: "CSS",
      image: this.url + "vue.jpg"
    },
    {
      id: "7",
      titulo: "Bootstrap",
      image: this.url + "react.jpg"
    },
    {
      id: "8",
      titulo: "jQuery",
      image: this.url + "vue.jpg"
    },
    {
      id: "9",
      titulo: "AWS",
      image: this.url + "react.jpg"
    },
    {
      id: "10",
      titulo: "Azure",
      image: this.url + "angular.jpg"
    },
    {
      id: "11",
      titulo: ".NET",
      image: this.url + "vue.jpg"
    },
  ];
  
  constructor(private modalService: NgbModal,
              private languageService: LanguageService,

  ) { }

  ngOnInit(): void {
    this.loadTranslations();
    this.changeLanguage();
  }
  
  loadTranslations() {
    this.languageService.getTranslations().subscribe(data => {
      this.general = data.general;
      this.about = data.about;
      this.item = data.technologies; 
      this.home = data.home;
    });
  }
  
  changeLanguage(){
    this.languageService.onLanguageChanged().subscribe(() => {
      this.loadTranslations();
    });
  }
  num: number = 0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 1,
    decimalPlaces: 0,
  };

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
