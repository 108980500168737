<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100 d-print-none" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h1 class="title"> {{ conversor.h1 }} </h1>
          <!-- <ul class="list-unstyled mt-4 mb-0">
            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Last Revised :</span> 23th Sep,
              2021</li>
          </ul> -->
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a href="javascript:void(0)">{{ conversor.ul }}</a></li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card-body">
            <h2>{{ conversor.h2 }}</h2>
          <p class="text-muted">{{ conversor.h3p1 }}</p>
          <p class="text-muted">{{ conversor.h3p2 }}</p>
        </div>
        <!-- <div class="form-container">
            <form>
              <div class="form-">
                <div class="mb-0">
                  <select class="form-select form-control" aria-label="Default select example">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="of">De:</label>
                  <select id="inputOf" class="form-control">
                    <option selected>Seleccionar...</option>
                    <option value="decimal">Decimal</option>
                    <option value="binary">Binario</option>
                    <option value="hexa">Hexadecimal</option>
                    <option value="octal">Octal</option>
                  </select>
                </div>
                <div class="form-group col-md-6"> 
                  <label for="to">A:</label>
                  <select id="inputA" class="form-control">
                    <option selected>Seleccionar...</option>
                    <option value="decimal">Decimal</option>
                    <option value="binary">Binario</option>
                    <option value="hexa">Hexadecimal</option>
                    <option value="octal">Octal</option>
                  </select>
                </div>
              </div>

              <label for="email">Email</label>
              <input type="email" id="email" name="email" placeholder="Enter a valid email address" required>

              <label for="message">Message</label>
              <textarea id="message" name="message" placeholder="Enter your message" required></textarea>

              <!-- <label>
                <input type="checkbox" name="terms" required>
                I accept the <a href="#">Terms of Service</a>
              </label> -- >

              <button type="submit">Envíe su solicitud</button>
            </form>
                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label">Your Name <span class="text-danger">*</span></label>
                        <div class="form-icon position-relative">
                          <i-feather name="user" class="fea icon-sm icons"></i-feather>
                          <input name="name" id="name" type="text" class="form-control ps-5" placeholder="First Name :">
                        </div>
                      </div>
                    </div>
                    <!--end col- ->
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label">Your Email <span class="text-danger">*</span></label>
                        <div class="form-icon position-relative">
                          <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                          <input name="email" id="email" type="email" class="form-control ps-5"
                            placeholder="Your email :">
                        </div>
                      </div>
                    </div>
                    <!--end col-- >
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="form-label">Subject</label>
                        <div class="form-icon position-relative">
                          <i-feather name="book" class="fea icon-sm icons"></i-feather>
                          <input name="subject" id="subject" class="form-control ps-5" placeholder="Your subject :">
                        </div>
                      </div>
                    </div>
                    <!--end col-- >
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="form-label">Comments</label>
                        <div class="form-icon position-relative">
                          <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                          <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                            placeholder="Your Message :"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end row- ->
                  <div class="row">
                    <div class="col-sm-12">
                      <input type="submit" id="submit" name="send" class="btn btn-primary" value="Send Message">
                    </div>
                    <!--end col- ->
                  </div>
                  <!--end row- ->
                </form>
          </div> -->

      </div>
      <div class="row justify-content-center">
        <!-- Forms Start -->
        <div class="col-md-6 " id="forms">
          <div class="component-wrapper rounded shadow">
            <div class="p-4 border-bottom">
              <h4 class="title mb-0"> Forms </h4>
            </div>

            <div class="p-4">
              <form>
                <div class="row">
                  <!-- Resto del formulario -->

                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">De: </label>
                      <div class="mb-0">
                        <select id="inputOf" class="form-select form-control" placeholder="Seleccionar" [(ngModel)]="selectedBaseFrom"
                          [ngModelOptions]="{standalone: true}" >
                          <option value="decimal">Decimal</option>
                          <option value="binary">Binario</option>
                          <option value="hexa">Hexadecimal</option>
                          <option value="octal">Octal</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- Resto de las opciones de entrada -->

                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">Ingrese el valor</label>
                      <div class="form-icon position-relative">
                        <i-feather name="book" class="fea icon-sm icons"></i-feather>
                        <input name="value" id="value" class="form-control ps-5" placeholder="Valor :"
                          [(ngModel)]="inputValue" [ngModelOptions]="{standalone: true}">
                      </div>
                    </div>
                  </div>

                  <!-- Resultados de las conversiones -->
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">{{ valor_1 }}</label>
                      <div class="form-icon position-relative">
                        <i-feather name="book" class="fea icon-sm icons"></i-feather>
                        <input name="resultBinary" id="resultBinary" class="form-control ps-5" [value]="value_1"
                          readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">{{ valor_2 }}</label>
                      <div class="form-icon position-relative">
                        <i-feather name="book" class="fea icon-sm icons"></i-feather>
                        <input name="resultHexa" id="resultHexa" class="form-control ps-5" [value]="value_2"
                          readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">{{ valor_3 }}</label>
                      <div class="form-icon position-relative">
                        <i-feather name="book" class="fea icon-sm icons"></i-feather>
                        <input name="resultOctal" id="resultOctal" class="form-control ps-5" [value]="value_3"
                          readonly>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <button type="button" id="converter" name="converter" class="btn btn-primary" (click)="convert()">
                      Convertir </button>
                  </div>
                </div>
              </form>

              <!-- Sección de logs para mostrar las operaciones -->
              <!-- <div class="row mt-4">
                <div class="col-md-12">
                  <h5>Operaciones realizadas:</h5>
                  <pre>{{ conversionLogs }}</pre>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9">
        <div class="card-body">
          <!-- <p class="text-muted">Para usar esta herramienta seleccione un tipo de sistema numerico, Decimal, Binario,
            Hexadecimal u Octal,
            despues escriba un valor deacuerdo a su selección en el campo y luego presione el botón Convertir.</p>
          <p class="text-muted">Si desconoce algún tipo de sistema se recomienda seleccionar decimal y este se
            sconvertira a su
            equivalente al resto de sistemas disponibles en nuestra herramienta.</p> -->

          <!-- <h5 class="card-title">We use your information to :</h5>
          <ul class="list-unstyled text-muted">
            <li>
              <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
              Digital Marketing Solutions for Tomorrow
            </li>
            <li>
              <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
              Our Talented & Experienced Marketing Agency
            </li>
            <li>
              <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
              Create your own skin to match your brand
            </li>
            <li>
              <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
              Digital Marketing Solutions for Tomorrow
            </li>
            <li>
              <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
              Our Talented & Experienced Marketing Agency
            </li>
            <li>
              <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
              Create your own skin to match your brand
            </li>
          </ul> -->

          <!-- <h5 class="card-title">Sistemas numericos:</h5>
          <p class="text-muted">Los sistemas de numeración son un conjunto de símbolos y reglas que permiten representar
            datos numéricos,
            la mayoría de ellos son posicionales, que se caracterizan porque un símbolo tiene distinto valor según la
            posición que ocupa en la cifra.
          </p> -->

          <!-- <a href="javascript:window.print()" class="btn btn-soft-primary d-print-none">Print</a> -->
        </div>

        <div class="card shadow rounded border-0">
          <div class="card-body">
            @for(decimals of decimal; track $index ){
              <h5 class="card-title">{{ decimals.title }}:</h5>
              <p class="text-muted">{{ decimals.p_1 }}
              </p>
              <P class="text-muted">{{ decimals.p_2 }}:</P>
                <ul class="list-unstyled text-muted">
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    {{ decimals.li_1}}
                  </li>
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    {{ decimals.li_2 }}
                  </li>
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    {{ decimals.li_3 }}
                  </li>
                  @if(decimals.li_4){
                    <li>
                      <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                      {{ decimals.li_4 }}
                    </li>
                  }
                  @if(decimals.li_5){
                    <li>
                      <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                      {{ decimals.li_5 }}
                    </li>
                  }
                </ul>
              @if(decimals.p_3){
                <p class="text-muted">{{ decimals.p_3 }}:</p>
              }
              @if(decimals.li_6 || decimals.li_7 ){
                <ul class="list-unstyled text-muted">
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    {{ decimals.li_6 }}
                  </li>
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    {{ decimals.li_7 }}
                  </li>
                </ul>
              }
              <!-- Sección de logs para mostrar las operaciones -->
              <!-- <div class="row mt-4">
                <div class="col-md-12">
                  <h5>Operaciones realizadas:</h5>
                  <pre>{{ conversionLogs }}</pre>
                </div>
              </div> -->
            }

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- 
<div class="p-4">
  <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#wishlist" class="btn btn-primary m-1">Wishlist
    Popup</a>
</div>

<div class="modal fade" id="wishlist" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content rounded shadow border-0">
      <div class="modal-body py-5">
        <div class="text-center">
          <div class="icon d-flex align-items-center justify-content-center bg-soft-danger rounded-circle mx-auto"
            style="height: 95px; width:95px;">
            <h1 class="mb-0"><i class="uil uil-heart-break align-middle"></i></h1>
          </div>
          <div class="mt-4">
            <h4>Your wishlist is empty.</h4>
            <p class="text-muted">Create your first wishlist request...</p>
            <div class="mt-4">
              <a href="javascript:void(0)" class="btn btn-outline-primary">+ Create new wishlist</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- <section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <h5 class="card-title">Overview :</h5>
            <p class="text-muted">Para usar esta herramienta seleccione un tipo de sistema numerico, Decimal, Binario,
              Hexadecimal u Octal,
              despues escriba un valor deacuerdo a su selección en el campo y luego presione el botón Convertir.</p>
            <p class="text-muted">Si desconoce algún tipo de sistema se recomienda seleccionar decimal y este se
              sconvertira a su
              equivalente al resto de sistemas disponibles en nuestra herramienta.</p>

            <h5 class="card-title">We use your information to :</h5>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Digital Marketing Solutions for Tomorrow
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Our Talented & Experienced Marketing Agency
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Create your own skin to match your brand
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Digital Marketing Solutions for Tomorrow
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Our Talented & Experienced Marketing Agency
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Create your own skin to match your brand
              </li>
            </ul>

            <h5 class="card-title">Information Provided Voluntarily :</h5>
            <p class="text-muted">In the 1960s, the text suddenly became known beyond the professional circle of
              typesetters and layout designers when it was used for Letraset sheets (adhesive letters on transparent
              film, popular until the 1980s) Versions of the text were subsequently included in DTP programmes such as
              PageMaker etc.</p>

            <a href="javascript:window.print()" class="btn btn-soft-primary d-print-none">Print</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->