<!-- Hero Start -->
<section class="home-slider position-relative" id="home">
  <div class="slider single-item">
    <owl-carousel-o id="customer-testi" [options]="customOptions">
      @for(slide of slides; track $index){
      <ng-template carouselSlide>
        <div class="bg-home slider-rtl-2 d-flex align-items-center"
          style="background-image: url('{{ slide.src }}'); background-size: cover; background-position: center center; height: 100vh; width: 100%;">
          <div class="bg-overlay bg-overlay-white"></div>
          <div class="container">
            <div class="carousel-caption d-none d-md-block">
              <h1 class="display-4 title-black fw-bold mb-3">{{ slide.title }}</h1>
              <p>{{ slide.descriptions }}</p>
            </div>
          </div>
        </div>
        <!--end slide-->
      </ng-template>
      }
    </owl-carousel-o>
  </div>
</section>

<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="py-4 border-bottom border-top">
  <div class="container">
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center py-4"></app-clients-logo>
  </div>
</section>
<!--end section-->
<!-- Partners End -->

<!-- How It Work Start -->
<section class="section bg-light border-bottom">
  <div class="container">
    <div class="col-12 text-center">
      <h1 class="title mb-4">{{ general.tic_mag }}</h1>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">{{ home.technologies }}</h4>
          <p class="text-muted para-desc mb-0 mx-auto">{{ general.p_1 }}
            <span class="text-primary fw-bold">{{ general.tic_mag }}</span>
            {{ general.p_2 }}
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <!-- tecnoligías -->
    <app-page-aboutus></app-page-aboutus>
    <br>
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 mt-4 pt-2">
        <img src="{{ methodology }}" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 pt-2">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">{{ home.methodology }}</h4>
          <p class="text-muted">{{ home.p_methodology }}
          <ul class="list-unstyled text-muted">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{ home.span_methodology_1 }}</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{ home.span_methodology_2 }}</li>
          </ul>
          <!-- <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="uil uil-angle-right-b align-middle"></i></a> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <!-- </div>
  <!--end container- ->

  <div class="container mt-100 mt-60"> -->
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <h4 class="title mb-4">{{ home.scrum }}</h4>
          <p class="text-muted">{{ home.p_scrum }}
          </p>
          <ul class="list-unstyled text-muted">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{ home.spam_scrum_1 }}</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{ home.spam_scrum_2 }}</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{ home.spam_scrum_3 }}</li>
          </ul>
          <!-- <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="uil uil-angle-right-b align-middle"></i></a> -->
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-6 order-1 order-md-2">
        <div class="card-body">
          <img src="{{ scrum }}" class="img-fluid" alt="">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">{{ home.solutions }}</h4>
          <p class="text-muted para-desc mb-0 mx-auto">{{ general.p_1 }}
            <span class="text-primary fw-bold">{{ general.tic_mag }}</span>{{ general.p_2 }}
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-services [servicesData]="servicesData"
      service_class="features feature-primary text-center mt-5"></app-services>
  </div>

  <!--end container-->
  <!-- Start CTA -->
  <div class="container-fluid mt-100 mt-60">
    <div class="rounded py-5 d-flex" style="background: url('{{ product }}') fixed; background-size: cover; background-position: center center;  width: 100%;">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section-title">
              <h2 class="fw-bold text-black mb-4" style="margin-top: 24px;">{{ home.our_products }}</h2>
              @for(product of products;track $index){
              <p class="para-desc text-black mb-0">{{ product.title }}
                <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#LoginForm"
                  (click)="openModal(product)" style="color: bisque;"> {{ home.see_more }}...</a>
              </p>
              <!-- <div class="mt-4">
                  <!-- <a href="javascript:void(0)" class="btn btn-primary">Shop Now</a> - ->
                  <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#LoginForm" class="btn btn-primary m-1"> Ver más</a>
                </div> -->

              <!-- Modal Content Start -->
              <div class="modal fade" id="LoginForm" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content rounded shadow border-0">
                    <div class="modal-body py-5">
                      <div class="text-center" *ngIf="selectedProduct">
                        <img [src]="selectedProduct.src" class="img-fluid" alt="" style="height: 200px; width:300px;">
                        <h4>{{ selectedProduct.title }}</h4>
                        <p class="text-muted mb-0">{{ selectedProduct.description }}</p>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ general.btn_close
                        }}</button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Modal Content End -->
              }
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </div>
  </div>
  <!--end container-->
  <!-- How It Work End -->

  <!-- Testi Start -->
  <div class="container">
    <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">{{ home.our_clients }}</h4>
            <p class="text-muted para-desc mx-auto mb-0">{{ home.p_our_clients_1 }}
              <span class="text-primary fw-bold">{{ general.tic_mag }}</span>{{ home.p_our_clients_2 }}
            </p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
      <div class="row justify-content-center">
        <div class="col-lg-9 mt-4 pt-2 text-center">
          <app-review-testmonial [reviewData]="reviewData" [firstimage]="1"></app-review-testmonial>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Testi End -->


<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Pricing End -->

<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">{{ home.contact_seccion }}</h4>
          <p class="text-muted para-desc mx-auto mb-0">{{ general.p_1 }} <span class="text-primary fw-bold">{{
              general.tic_mag }}</span> {{ home.p_contact_seccion_1 }}</p>

          <div class="mt-4 pt-2">
            <!-- <a href="page-contact-two" class="btn btn-primary mt-4"><i class="mdi mdi-phone"></i> Contact us</a> -->
            <!-- <a href="https://docs.google.com/forms/d/1Rts-0rQvx9cC5XVwi9NdvWxEaKATus3KWmAc5vIiLn4/edit" target="_blank" class="btn btn-primary">Pide un presupuesto </a> -->
            <a routerLink="/contact-us" class="btn btn-primary">{{ contact.contact }}</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<!-- Back to top -->
<a href="javascript:void(0)" [ngxScrollTo]="'#home'" id="back-to-top" class="btn btn-icon btn-primary back-to-top">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->