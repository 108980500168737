<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" style="background: url('assets/images/headers/aboutus.jpg');" id="home">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading title-heading">
                    <h1 class="text-white title-dark"> {{ about.title }} </h1>
                    <p class="text-white-50 para-desc mb-0 mx-auto">{{ general.p_1 }} {{ general.tic_mag }} {{ general.p_2 }}
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <!-- <li class="breadcrumb-item"><a routerLink="/">{{ about.tic_mag }}</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)">{{ general.company }}</a></li> -->
                    <li class="breadcrumb-item active" aria-current="page"><a href="javascript:void(0)">{{ about.about }}</a></li>
                </ul>
            </nav>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
    </div>
</div>
<!--Shape End-->

<!-- About Start -->
<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img src="assets/images/about/img-video/img-video.png" class="rounded img-fluid mx-auto d-block" alt="">
                    <!-- <div class="play-icon">
                        <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn">
                            <i class="mdi mdi-play text-primary rounded-circle shadow"></i>
                        </a>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <iframe src="https://www.youtube.com/embed/fcR2TsO0yak?autoplay=1&controls=1&showinfo=0&modestbranding=1rel=0&mute=1" height="450" width="780" frameborder="0" allowfullscreen allow="autoplay;"></iframe>
                                <!-- <iframe src="https://www.youtube.com/embed/?v=trErf2psdnQ" name='myiFrame' width='400px' height='200px'></iframe> -- >
                            </div>
                        </ng-template>
                    </div> -->
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title ms-lg-4">
                    <h4 class="title mb-4">{{ about.mission }}</h4>
                    <p class="text-muted">{{ about.mission_p_1 }} <span class="text-primary fw-bold">{{ general.tic_mag
              }}</span>{{ about.mission_p_2 }}
                    </p>
                </div>
                <div class="section-title ms-lg-4">
                    <h4 class="title mb-4">{{ about.vision }}</h4>
                    <p class="text-muted">{{ about.vision_p }}
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">{{ about.Values }}</h4>
                    <p class="text-muted para-desc mx-auto mb-0">{{ general.p_1 }}
                        <span class="text-primary fw-bold">{{ general.tic_mag }} </span>{{ about.Values_p }}
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
        <app-features></app-features>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- About End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->