<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      @if(navClass !== 'nav-light'){
      <a class="logo" routerLink="/">
        <!-- <img src="assets/images/logos/mag/logo-dark.png" class="l-dark logo-light-mode" height="24" alt=""> -->
        <img src="assets/images/logos/mag/logo-light.png" class="logo-dark-mode" height="50" alt="">
      </a>
      }
      <div class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </div>
      </div>

      @if(navClass === 'nav-light'){
      <a class="logo" routerLink="/">
        <!-- <img src="assets/images/logos/mag/logo-dark.png" class="l-dark" height="24" alt=""> -->
        <img src="assets/images/logos/mag/logo-light.png" class="l-light" height="50" alt="">
      </a>
      }
    </div>

    <!--  idiomas  -->

    <ul class="buy-button mb-0 navigation-menu" [class]="navClass">
      <li>
        <a href="https://wa.me/{{ contact.phone_a }}?text={{ contact.whatsapp_message }}" target="_blank">
          <!-- <img src="{{ whastApp }}" alt="WhastApp logo" class="me-2" style="width: 90px;"> -->
          <img src="{{ whastApp }}" alt="WhastApp logo" class="me-2" style="width: 20px;">WhastApp
        </a>
      </li>
      <li class="has-submenu">
        <a href="javascript:void(0)" (click)="onMenuClick($event)"><img [src]="selectedFlag" alt="Flag" class="me-2"
            style="width: 20px;">{{ selectedLanguage }}</a><span class="menu-arrow"></span>
        <!-- </button> -->
        <ul class="submenu">
          <li>
            <a href="javascript:void(0)" (click)="changeLanguage('es')">
              <img src="{{ esFlag }}" alt="Spanish Flag" class="me-2" style="width: 20px;">
              Español
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="changeLanguage('en')">
              <img src="{{ enFlag }}" alt="English Flag" class="me-2" style="width: 20px;">
              English
            </a>
          </li>
        </ul>
        <!-- </div> -->
      </li>
    </ul>
    <!--Login button End-->

    <!-- MAG -->
    @if(Menuoption == 'center'){
    <div id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/" class="nav-link-ref">{{ lang.home }}</a></li>


        <li><a routerLink="/about-us" class="nav-link-ref">{{ lang.about }}</a></li>

        <!-- <li class="has-submenu parent-menu-item">
          <a href="javascript:void(0)">Shop</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li><a routerLink="/shop-fullwidth-grids" class="nav-link-ref">Fullwidth Grid</a></li>
            <li><a routerLink="/shop-grids" class="nav-link-ref">Product Grids</a></li>
            <li><a routerLink="/shop-fullwidth-lists" class="nav-link-ref">Fullwidth List</a></li>
            <li><a routerLink="/shop-lists" class="nav-link-ref">Product List</a></li>
            <li><a routerLink="/shop-product-detail" class="nav-link-ref">Product Details</a></li>
            <li><a routerLink="/shop-cart" class="nav-link-ref">Shop Cart</a></li>
            <li><a routerLink="/shop-checkouts" class="nav-link-ref">Checkouts</a></li>
            <li><a routerLink="/shop-myaccount" class="nav-link-ref">My Account</a></li>
          </ul>
        </li> -->

        <!-- <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">{{ lang.landing }}</a><span class="menu-arrow"></span>
          <ul class="submenu megamenu">
            <li>
              <ul>
                <li class="megamenu-head"><i class="uil uil-book-open fs-6 align-middle"></i> Landing Pages</li>
                <!-- <li><a class="nav-link-ref" routerLink="/index-saas">Saas</a></li> -->
        <!-- <li><a class="nav-link-ref" routerLink="/index-classic-saas">Classic Saas</a></li>
                  <li><a class="nav-link-ref" routerLink="/index-agency">Agency</a></li>
                  <li><a class="nav-link-ref" routerLink="/index-apps">Application</a></li>
                  <li><a class="nav-link-ref" routerLink="/index-classic-app">Classic Application</a></li>
                  <li><a class="nav-link-ref" routerLink="/index-studio">Studio</a></li>
                  <li><a class="nav-link-ref" routerLink="/index-marketing">Marketing</a></li>
                  <li><a class="nav-link-ref" routerLink="/index-enterprise">Enterprise</a></li>
                  <li><a class="nav-link-ref" routerLink="/index-services">Service</a></li>
                  <li><a class="nav-link-ref" routerLink="/index-payments">Payments</a></li>
                  <li><a class="nav-link-ref" routerLink="/index-it-solution">IT Solution </a></li>
                  <li><a class="nav-link-ref" routerLink="/index-it-solution-two">IT Solution Two</a></li> -- >
              </ul>
            </li>
          </ul>
        </li> -->

        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">{{ lang.pages }}</a><span
            class="menu-arrow"></span>
          <ul class="submenu">
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> {{ lang.about
                }}</a><span class="submenu-arrow"></span>
              <ul class="submenu">
                <!-- <li><a class="nav-link-ref" routerLink="/page-aboutus"> About Us</a></li> -->
                <li><a class="nav-link-ref" routerLink="/about-us"> {{ lang.mission_vision }} </a></li>
                <!-- <li><a class="nav-link-ref" routerLink="/page-services">Services</a></li>
                <li><a class="nav-link-ref" routerLink="/page-history">History </a></li>
                <li><a class="nav-link-ref" routerLink="/page-team"> Team</a></li>
                <li><a class="nav-link-ref" routerLink="/page-pricing">Pricing</a></li> -->
              </ul>
            </li>
            <li><a routerLink="/contact-us" class="nav-link-ref"> {{ lang.contact }} </a></li>
          </ul>
        </li>
      </ul>
      <!--end navigation menu-->
    </div>
    }
    <!--end navigation-->

  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->