<div class="d-flex">
  <ul ngbNav #nav="ngbNav" orientation="vertical" class="nav-pills rounded shadow p-3 mb-0 mt-4 col-md-4
          pt-2">
    @for(item of technologies;track $index){
    <li ngbNavItem="">
      <a ngbNavLink class="rounded">
        <div class="text-center pt-1 pb-1">
          <h6 class="mb-0">{{ item.titulo }}</h6>
        </div>
      </a>
      <ng-template ngbNavContent>
        <div class="show active p-4 rounded shadow" id="developing" role="tabpanel" aria-labelledby="webdeveloping">
          <img src="{{ item.image }}" class="img-fluid rounded shadow" alt="">
          @if(currentLanguage == 'es'){
          <div class="mt-4">
            <p class="text-muted">{{ item.descripcion }}</p>
          </div>
          }
          @if(currentLanguage != 'es'){
          <div class="mt-4">
            <p class="text-muted">{{ item.description }}</p>
          </div>
          }
        </div>
      </ng-template>
    </li>
    }
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-4 ms-4"></div>
</div>
<!--end row-->