import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})

/**
 * Index Component
 */
export class IndexComponent implements OnInit {
  
  selectedProduct: any;
  // Sección tecnologías
  technologies: any [] = [];  

  products = [];

  slides: any[] = [];
  /**
   * Services Data
   */
  servicesData = [];  
  /**
   * Client Testimonial Data
   */
  reviewData = [];
 url = "assets/images/technologies/";  
   x: any[] = [
    {
      titulo: "PHP Laravel",
      image: this.url + "angular.jpg"
    },
    {
      titulo: "C#",
      image: this.url + "react.jpg"
    },
    {
      titulo: "Angular",
      image: this.url + "angular.jpg"
    },
    {
      titulo: "SQL",
      image: this.url + "react.jpg"
    },
    {
      titulo: "HTML5",
      image: this.url + "angular.jpg"
    },
    {
      titulo: "CSS",
      image: this.url + "vue.jpg"
    },
    {
      titulo: "Bootstrap",
      image: this.url + "react.jpg"
    },
    {
      titulo: "jQuery",
      image: this.url + "vue.jpg"
    },
    {
      titulo: "AWS",
      image: this.url + "react.jpg"
    },
    {
      titulo: "Azure",
      image: this.url + "angular.jpg"
    },
    {
      titulo: ".NET",
      image: this.url + "vue.jpg"
    },
  ];
  methodology = "assets/images/img/SEO_SVG.svg";
  scrum = "assets/images/home/scrum/scrum.png";
  product = "assets/images/home/products/tienda.png";

  developmentForm!: UntypedFormGroup;
  submitted = false;
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;

  /**
   * Partners slider
   */
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    autoplay: true,
    navSpeed: 1000,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      900: {
        items: 1
      }
    },
    nav: false
  };

  openModal(product: any) {
    this.selectedProduct = product;
  }

  home: any = {};
  general: any = {};
  contact: any = {};

  constructor(private languageService: LanguageService,
  ) { }
  
private scrollPosition: number = 0;

  ngOnInit(): void {
    this.loadTranslations();
    this.languageService.onLanguageChanged().subscribe(() => {
      this.scrollPosition = window.scrollY;
      this.loadTranslations();
      setTimeout(() => window.scrollTo(0, this.scrollPosition), 0); // Restaurar la posición del scroll
    });
  }
    loadTranslations() {
    this.languageService.getTranslations().subscribe(data => {
      this.general = data.general;
      this.home = data.home;
      this.contact = data.contact;
      // Asigna los valores del JSON al array 
      this.slides = data.slides; 
      this.technologies = data.technologies; 
      this.servicesData = data.servicesData; 
      this.products = data.products; 
      this.reviewData = data.reviewData; 
    });
  }
  
  changeLanguage(language: string){
  this.languageService.setLanguage(language);
    this.languageService.onLanguageChanged().subscribe(() => {
      this.loadTranslations();
    });
  }
}
