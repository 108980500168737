import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {
  public url = environment.url;
  constructor(private http: HttpClient) { }
 
  sendMail(form)
  {
    return this.http.post<any[]>(this.url + 'Contactanos/SendMail',form);
    
  }
  dbContact(form)
  {
    return this.http.post<any[]>(this.url + 'Contact/DBContact',form);   
  }
}
