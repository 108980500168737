import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class   LanguageService {

  private language = 'es';
  private languageChanged = new Subject<string>();

  constructor(private http: HttpClient) {
    // Puedes cargar el idioma de localStorage o de alguna configuración inicial
    const idioma = localStorage.getItem('language');
    if (idioma) {
      this.setLanguage(idioma);
      }
  }
  getCurrentLanguage(): string {
    return this.language;
  }
  setLanguage(language: string) {
    this.language = language;
    this.languageChanged.next(this.language); // Notificar cambio de idioma
  }

  getTranslations(): Observable<any> {
    return this.http.get(`assets/i18n/${this.language}.json`);
  }

  onLanguageChanged(): Observable<string> {
    return this.languageChanged.asObservable();
  }
}
