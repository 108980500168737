import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language.service';

interface member {
  profile: string,
  list: string[],
  name: string,
  designation: string,
};

@Component({
  selector: 'app-page-aboutus',
  templateUrl: './page-aboutus.component.html',
  styleUrls: ['./page-aboutus.component.css']
})

/**
 * Aboutus Component
 */
export class PageAboutusComponent implements OnInit {

  url = "assets/images/home/technologies/";
  technologies: any[] = [
    {
      titulo: "PHP Laravel",
      image: this.url + "php.png",
      descripcion: "PHP Laravel es nuestro framework preferido para el desarrollo de aplicaciones web robustas y escalables. Con su arquitectura elegante y funcionalidades avanzadas, garantizamos soluciones rápidas y eficientes.",
      description: "PHP Laravel is our preferred framework for developing robust and scalable web applications. With its elegant architecture and advanced features, we ensure fast and efficient solutions."
    },
    {
      titulo: "C#",
      image: this.url + "sharp.png",
      descripcion: "C# es nuestro lenguaje de elección para desarrollar aplicaciones de escritorio y web de alto rendimiento. Aprovechamos su versatilidad y potencia para crear soluciones robustas y seguras.",
      description: "C# is our language of choice for developing high-performance desktop and web applications. We leverage its versatility and power to create robust and secure solutions."
    },
    {
      titulo: "Angular",
      image: this.url + "angular.png",
      descripcion: "Angular nos permite construir aplicaciones web dinámicas y de alto rendimiento. Utilizamos este framework para desarrollar interfaces de usuario intuitivas y reactivas que mejoran la experiencia del usuario.",
      description: "Angular allows us to build dynamic and high-performance web applications. We use this framework to develop intuitive and responsive user interfaces that enhance user experience."
    },
    {
      titulo: "SQL",
      image: this.url + "sql.png",
      descripcion: "SQL es la columna vertebral de nuestras soluciones de gestión de bases de datos. Diseñamos y optimizamos bases de datos relacionales para garantizar un acceso rápido y seguro a la información.",
      description: "SQL is the backbone of our database management solutions. We design and optimize relational databases to ensure fast and secure access to information."
    },
    {
      titulo: "HTML5",
      image: this.url + "html5.png",
      descripcion: "HTML5 es fundamental para el desarrollo de páginas web modernas y adaptables. Utilizamos sus capacidades avanzadas para crear sitios web interactivos y accesibles desde cualquier dispositivo.",
      description: "HTML5 is fundamental for the development of modern and adaptable web pages. We utilize its advanced capabilities to create interactive websites accessible from any device."
    },
    {
      titulo: "CSS",
      image: this.url + "css.png",
      descripcion: "CSS es esencial para diseñar interfaces atractivas y coherentes. Creamos estilos personalizados que mejoran la usabilidad y la estética de nuestras aplicaciones web.",
      description: "CSS is essential for designing attractive and consistent interfaces. We create custom styles that enhance the usability and aesthetics of our web applications."
    },
    {
      titulo: "Bootstrap",
      image: this.url + "bootstrap.png",
      descripcion: "Bootstrap nos permite desarrollar sitios web responsivos y móviles. Utilizamos este popular framework para garantizar que nuestras aplicaciones se vean geniales en cualquier dispositivo.",
      description: "Bootstrap enables us to develop responsive and mobile-first websites. We use this popular framework to ensure that our applications look great on any device."
    },
    {
      titulo: "jQuery",
      image: this.url + "jquery.png",
      descripcion: "jQuery simplifica la manipulación del DOM y la gestión de eventos en nuestras aplicaciones web. Implementamos este potente framework para añadir interactividad y mejorar la experiencia del usuario.",
      description: "jQuery simplifies DOM manipulation and event handling in our web applications. We implement this powerful framework to add interactivity and enhance the user experience."
    },
    {
      titulo: "AWS",
      image: this.url + "aws.png",
      descripcion: "Amazon Web Services (AWS) nos proporciona la infraestructura en la nube necesaria para desplegar aplicaciones escalables y seguras. Utilizamos sus servicios para manejar cargas de trabajo críticas de manera eficiente.",
      description: "Amazon Web Services (AWS) provides us with the cloud infrastructure necessary to deploy scalable and secure applications. We use its services to efficiently handle critical workloads."
    },
    {
      titulo: "Azure",
      image: this.url + "azure.png",
      descripcion: "Microsoft Azure es nuestra opción preferida para soluciones en la nube de nivel empresarial. Ofrece herramientas avanzadas para el desarrollo, despliegue y gestión de aplicaciones globales.",
      description: "Microsoft Azure is our preferred choice for enterprise-level cloud solutions. It offers advanced tools for developing, deploying, and managing global applications."
    },
    {     
      titulo: ".NET",     
      image: this.url + "net.png",
      descripcion: ".NET es nuestro marco de trabajo principal para desarrollar aplicaciones escalables y de alto rendimiento. Aprovechamos su ecosistema rico y su compatibilidad con múltiples lenguajes.",
      description: ".NET is our primary framework for developing scalable and high-performance applications. We leverage its rich ecosystem and multi-language compatibility."
    },
  ];
  home: any = {};
  currentLanguage: string = '';
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true

  constructor(private languageService: LanguageService,
  ) { }


  ngOnInit()  {
    this.currentLanguage = this.languageService.getCurrentLanguage();
    this.languageService.onLanguageChanged().subscribe(language => {
      this.currentLanguage = language;
    });
  }

}
