<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" style="background: url('assets/images/headers/contac.png'); background-size: cover;  width: 100%;" id="home">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h1 class="title mb-0">{{ contact.contact }}</h1>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <!-- <li class="breadcrumb-item"><a routerLink="/index">{{ about.tic_mag }}</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)">{{ contact.page }}</a></li> -->
                    <li class="breadcrumb-item"><a href="javascript:void(0)">{{ contact.contact_a }}</a></li>
                </ul>
            </nav>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Contact -->
<section class="section pb-0">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="card border-0 text-center features feature-primary feature-clean">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-phone d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="content mt-4">
                        <h5 class="fw-bold">{{ contact.phone }}</h5>
                        <p class="text-muted">{{ contact.phone_p }}</p>
                        <!-- Enlace para WhatsApp -->
                        <a href="https://wa.me/{{ contact.phone_a }}?text={{ contact.whatsapp_message }}" class="text-primary" target="_blank">{{ contact.phone_a }}</a>
                    </div>
                </div>
            </div>

            <!-- <div class="col-md-6">
                <div class="card border-0 text-center features feature-primary feature-clean">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-phone d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="content mt-4">
                        <h5 class="fw-bold">{{ contact.phone }}</h5>
                        <p class="text-muted">{{ contact.phone_p }}</p>
                        <a href="tel:{{ contact.phone_a }}" class="text-primary">{{ contact.phone_a }}</a>
                    </div>
                </div>
            </div> -->
            <!--end col-->

            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="card border-0 text-center features feature-primary feature-clean">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-envelope d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="content mt-4">
                        <h5 class="fw-bold">{{ contact.email }}</h5>
                        <p class="text-muted">{{ contact.email_p }}</p>
                        <a href="mailto:{{ emailContact }}" class="text-primary">{{ emailContact }}</a>
                    </div>
                </div>
            </div>
            <!--end col-->

        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
                <div class="card shadow rounded border-0">
                    <div class="card-body py-5">
                        <h4 class="card-title">{{ contact.contact }}!</h4>
                        <div class="custom-form mt-3">

                            <form [formGroup]="form" #contactForm="ngForm" class="form" (ngSubmit)="onSubmit(contactForm)">
                                <p id="error-msg" class="mb-0"></p>
                                <div id="simple-msg"></div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="mb-3">
                                            <label class="form-label">{{ contact.name }} <span class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                                <input formControlName="name" name="name" id="name" type="text" class="form-control ps-5" placeholder="{{ contact.name }} :">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="mb-3">
                                            <label class="form-label">{{ contact.email }} <span class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                <input formControlName="email" name="email" id="email" type="email" class="form-control ps-5" placeholder="{{ contact.email }} :">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="mb-3">
                                            <label class="form-label">{{ contact.subject }}</label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                                <input formControlName="subject" name="subject" id="subject" class="form-control ps-5" placeholder="{{ contact.subject }} :">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="mb-3">
                                            <label class="form-label">{{ contact.message }} <span class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                                                <textarea formControlName="message" name="message" id="message" rows="4" class="form-control ps-5" placeholder="{{ contact.message }} :"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-grid">
                                            <button type="submit" id="submit" name="send" class="btn btn-primary">{{ contact.send_message }}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-6 order-1 order-md-2">
                <div class="card border-0">
                    <div class="card-body p-0">
                        <img src="assets/images/contact.svg" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->