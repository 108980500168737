import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '../../services/language.service';
import { FormBuilder, NgForm, UntypedFormGroup, Validators } from '@angular/forms';
import { ContactoService } from '../../services/contacto.service';

@Component({
  selector: 'app-page-contact-one',
  templateUrl: './page-contact-one.component.html',
  styleUrls: ['./page-contact-one.component.css']
})

/**
 * Page Contact-One Component
 */
export class PageContactOneComponent implements OnInit {
  
  form: UntypedFormGroup;

      // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true
  
  about: any = {};
  contact: any = {};

  idioma: string;

  emailContact = "contacto@ticdesarrollomag.com";
  nameContact = "magContacto";
     
  constructor(private modalService: NgbModal,
              private languageService: LanguageService,
              private fb: FormBuilder,
              private contactoService: ContactoService,
  ) { }

private scrollPosition: number = 0;
  ngOnInit(): void {
    this.loadTranslations();
    this.buildForm();
    this.languageService.onLanguageChanged().subscribe(() => {
      this.scrollPosition = window.scrollY;
      this.loadTranslations();
      setTimeout(() => window.scrollTo(0, this.scrollPosition), 0); // Restaurar la posición del scroll
    });
  }
  
  loadTranslations() {
    this.languageService.getTranslations().subscribe(data => {
      this.about = data.about;
      this.contact = data.contact;
    });
  }
  
  changeLanguage(language: string){
  this.languageService.setLanguage(language);
  this.idioma = this.languageService.getCurrentLanguage();
    this.languageService.onLanguageChanged().subscribe(() => {
      this.loadTranslations();
    });
  }

  mapView(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true })
  }
  
  buildForm() {
    this.form = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(5)]],
    email: ['', [Validators.required, Validators.email]],
    subject: ['', [Validators.required]],
    message: ['', [Validators.required]],
    nameContact: [this.nameContact],
    emailContact: [this.emailContact],
    });
  }

  validateForm(form: NgForm): string | null {
    const { name, email, subject, message } = form.value;
  
    let errorMsg: string | null = null;
  
    if (!name) {
      errorMsg = this.idioma === 'es' ? "Por favor ingrese un Nombre" : "Please enter a Name";
    } else if (!email) {
      errorMsg = this.idioma === 'es' ? "Por favor ingrese un Email" : "Please enter an Email";
    } else if (!subject) {
      errorMsg = this.idioma === 'es' ? "Por favor ingrese un Asunto" : "Please enter a Subject";
    } else if (!message) {
      errorMsg = this.idioma === 'es' ? "Por favor ingrese un Mensaje" : "Please enter a Message";
    }
  
    if (errorMsg) {
      document.getElementById("error-msg").innerHTML = `<div class='alert alert-warning error_message'>${errorMsg}</div>`;
      this.fadeIn();
    }
  
    return errorMsg;
  }
  
  onSubmit(form: NgForm) {
    this.idioma = this.languageService.getCurrentLanguage(); // Obtener el idioma actual
  
    const error = this.validateForm(form);
  
    if (!error) {
  
      this.contactoService.dbContact(form.value).subscribe(
        response => {
          // Manejar la respuesta de la API
          document.getElementById("error-msg").innerHTML = this.idioma === 'es'
            ? "<div class='alert alert-success'>¡Mensaje enviado exitosamente!</div>"
            : "<div class='alert alert-success'>Message sent successfully!</div>";
          this.fadeIn();
          this.enviar();
          form.reset();
        },
        error => {
          // Manejar errores
          document.getElementById("error-msg").innerHTML = this.idioma === 'es' 
            ? "<div class='alert alert-danger'>Hubo un error al enviar tu mensaje.</div>" 
            : "<div class='alert alert-danger'>There was an error sending your message.</div>";
          this.fadeIn();
          form.reset();
        }
      );
    }
  }
 
  fadeIn() {
    const fade = document.getElementById("error-msg");
    let opacity = 0;
    const intervalID = setInterval(() => {
      if (opacity < 1) {
        opacity += 0.1;
        fade.style.opacity = opacity.toString();
      } else {
        clearInterval(intervalID);
      }
    }, 100);
  }
  enviar() {
    this.contactoService.dbContact(this.form.valid).subscribe((res) => {
      this.form.reset();
    });
  }
}
